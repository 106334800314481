.my-route-numeric-input {
  height: 42.25px;
  width: 100px;
}

.fadeOutLeft {
  visibility: collapse;
  transition: visibility 0.5s, opacity 0.5s linear;
}

.add-more-contact {
  padding: 5px;
  border: 1px gold solid;
  cursor: pointer;
  border-radius: 4px;
}