@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Condensed&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
/* ---------------------------------------------------------------------------------------------------- */
body {
  font-family: 'Ubuntu', var(--bs-font-sans-serif);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.font-condensed {
  font-family: 'Ubuntu Condensed', 'Ubuntu', var(--bs-font-sans-serif);
}
.font-monospace {
  font-family: 'Ubuntu Mono', 'Ubuntu', var(--bs-font-monospace) !important;
  font-variant-numeric: diagonal-fractions;
}
.fw-300 {font-weight: 300}
.fw-400 {font-weight: 400}
.fw-500 {font-weight: 500}
.fw-700 {font-weight: 700}
/* ---------------------------------------------------------------------------------------------------- */
.separator {
}
/* ---------------------------------------------------------------------------------------------------- */
.logo svg {
  fill: var(--bs-pink);
  width: .6em;
  height: .6em;
  margin-top: -.6em;
  margin-right: -.6em;
  position: relative;
  left: -.15em;
  top: -.1em;
}
.logo.logo-lg {
  font-size: 4rem;
}
/* ---------------------------------------------------------------------------------------------------- */
.max-w-md {
  max-width: 450px;
}
/* ---------------------------------------------------------------------------------------------------- */
#feedback-text {
  min-height: 10em;
  max-height: 15em;
}
/* ---------------------------------------------------------------------------------------------------- */
--.nav-link {
  max-height: 36px;
}
--#navbar-menu .nav-link {
  transform: translateY(-2px);
}
.avatar {
  object-fit: contain;
  object-position: center;
}
.avatar-md {
  width: 36px;
  height: 36px;
}
/* ---------------------------------------------------------------------------------------------------- */
.icon {
  display: inline-block;
  vertical-align: text-top;
  fill: currentColor;
  width: 1em;
  height: 1em;
}
.icon-16 {
  width: 16px;
  height: 16px;
}
.icon-24 {
  width: 24px;
  height: 24px;
}
/* ---------------------------------------------------------------------------------------------------- */
.widget-chart {
  height: 500px;
}
.widget-chart-sm {
  height: 330px;
}
.widget-cards .progress {
  height: 1.5em;
  font-size: 1em;
}
.widget-cards .progress-bar {
  min-width: 2.5em;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 9000;
}

[for=""] {
  font-size: 10em !important;
}
:root {
  --rgb-blue: 32, 107, 196;
  --rgb-indigo: 102, 126, 234;
  --rgb-purple: 122, 81, 149;
  --rgb-pink: 239, 86, 117;
  --rgb-red: 245, 101, 101;
  --rgb-orange: 237, 137, 54;
  --rgb-yellow: 255, 166, 0;
  --rgb-green: 72, 187, 120;
  --rgb-teal: 56, 178, 172;
  --rgb-cyan: 23, 162, 184;

  --bs-white: #fff;
  --bs-gray: #718096;
  --bs-gray-dark: #2d3748;

  --bs-light: #f7fafc;
  --bs-dark: #1a202c;

  --bs-azure: #45aaf2;
  --bs-lime: #94d82d;
}
