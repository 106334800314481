// elector

$min-contrast-ratio:		2.5 ;
$enable-negative-margins:	true ;
$link-decoration:			none ;
$font-size-root:			14px ;

// $blue:    #206bc4 ;
// $indigo:  #6574cd ;
// $purple:  #a55eea ;
// $pink:    #f66d9b ;
// $red:     #cd201f ;
// $orange:  #ff922b ;
// $yellow:  #fab005 ;
// $green:   #5eba00 ;
// $teal:    #2bcbba ;
// $cyan:    #17a2b8 ;

$gray-100:	#f7fafc;
$gray-200:	#edf2f7;
$gray-300:	#e2e8f0;
$gray-400:	#cbd5e0;
$gray-500:	#a0aec0;
$gray-600:	#718096;
$gray-700:	#4a5568;
$gray-800:	#2d3748;
$gray-900:	#1a202c;

$blue:		#206bc4;
$indigo:  	#667eea;
$purple:	#7a5195;
$pink:		#ef5675;
$red:		#f56565;
$orange:	#ed8936;
$yellow:	#ffa600;
$green:		#48bb78;
$teal:		#38b2ac;
$cyan:		#17a2b8;

@import "../node_modules/bootstrap/scss/functions";

$azure:   #45aaf2 ;
$lime: 	  #94d82d ;

@import "../node_modules/bootstrap/scss/variables";

$custom-colors: (
        "blue":    $blue,
        "indigo":  $indigo,
        "purple":  $purple,
        "pink":    $pink,
        "red":     $red,
        "orange":  $orange,
        "yellow":  $yellow,
        "green":   $green,
        "teal":    $teal,
        "cyan":    $cyan,

        "azure":  $azure,
        "lime":   $lime,
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

@import "../node_modules/bootstrap/scss/bootstrap";

// #003f5c
// #2f4b7c
// #665191
// #a05195
// #d45087
// #f95d6a
// #ff7c43
// #ffa600
.btn-success, .btn-outline-green:hover, .btn-success:hover, .btn-outline-success:hover, .btn-check:checked + .btn-outline-green {
  color: #FFFFFF !important;
}
.react-toast-notifications__container {
  z-index: 9999 !important;
}

.emergency-help-button:hover {
  opacity: 0.2;
  cursor: pointer;
  transition: opacity 0.3s;
}
.app-version-container {
  position: fixed;
  bottom: 0;
  right: 1rem;
}
.clickable-href {
  color: $blue;
  cursor: pointer;
}
.react-tel-input .special-label {
  display: none;
}
.cursor-pointer {
  cursor: pointer;
}
.form-label-required:after {
  content: ' *';
  color: red;
}
.btn-green {
  color: white;
}
