.profile-text-sm {
    font-size: 14px
}
.profile-input-label {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
}
.PhoneInputInput {
    border-radius: .25rem;
    border: 1px solid #ced4da;
    height: 40px;
    padding: 5px 0 5px 7px;
}

.direction-upside-down-checkbox {
    width: 24px;
    height: 24px;
}